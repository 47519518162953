.App {
  text-align: center;
  font-family: 'YoungSerif';

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: 'YoungSerif';
  src: url('/public/YoungSerif-Regular.ttf') format('truetype');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.heading {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;

}

.logo {
  height: 50px;
  border-radius: 10px;
}

.preview-img {
  width: 100px;
  height: 100px;
}

@media screen and (min-width: 800px) {
  .preview-img {
    width: 300px;
    height: 300px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* background-color: darkslategrey; */
  background-image: url('/public/bg-8-m.jpg');
  background-size: cover;
  /* position: relative; */
  /* padding: 30px; */
}

@media screen and (min-width: 800px) {
  .body {
    background-image: url('/public/bg-8.jpg');

  }
}

.price_info {
  color: whitesmoke;
}

.face_grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  margin-top: 20px;

  /* position: absolute; */
  /* top: 100px; */

  /* background-color: darkslategrey; */
  /* background-image: url('/public/bg-6.png'); */

}

.face-flex {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-evenly;
  /* width: 100%; */
  flex-wrap: wrap;
  max-width: 1057px;
  /* 1024 + 32*/
  gap: 1px;
  margin: 20px;
  /* padding: 50px; */
  /* border-radius: 10px; */
  /* background-color: rgb(255, 255, 255, 0.2); */
}

.face {
  width: 32px;
  height: 32px;
  /* border: lightgray 1px groove; */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 2px 1px 0px rgba(101, 100, 100, 0.75);
  font-size: small;
  font-weight: 100;
}

.face_first {
  /* background-color: rgb(255, 215, 0, 0.7); */
  background-color: #c0ea0a;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: green 1px; */
}

.face_second {
  /* background-color: rgb(250, 250, 210, 0.7); */
  background-color: rgba(224, 235, 172, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: lightgreen 1px; */
}

.face_third {
  background-color: rgb(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: white 1px; */
}

.node_first {
  /* background-color: #853ced; */
  background-color: #b9a6e0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.node_second {
  background-color: #d5cfdf;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.face:hover {
  transform: scale(150%);
}

.stripe_button {
  width: 100%;
  height: 40px;
  margin-top: 10px;
}

.stripe_error {
  color: red;
  text-align: center;
}

.price_list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.price_list_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.price_grid_text_container {
  display: flex;
  align-items: center;
  margin: 0px 5px;
}

.menu_container {
  width: 95%;
}

@media screen and (min-width:800px) {
  .menu_container {
    width: auto;
  }
}

.price_grid_text {
  margin: 0;
  padding: 0;
  color: whitesmoke;
}

.price_grid {
  display: none;
  grid-template-columns: auto auto auto;
  grid-template-rows: repeat(2, 1fr);
}

@media screen and (min-width:800px) {
  .price_grid {
    display: grid;

  }
}

.price_flex_mobile {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}


@media screen and (min-width:800px) {
  .price_flex_mobile {
    display: none;
  }
}


.price_info_color_first {
  width: 20px;
  height: 20px;
  background-color: rgba(192, 234, 10, 1);
  box-shadow: 1px 2px 1px 0px rgba(101, 100, 100, 0.75);
  margin: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
  font-weight: 100;
}

.price_info_color_second {
  width: 20px;
  height: 20px;
  background-color: rgb(212, 237, 101, 1);
  box-shadow: 1px 2px 1px 0px rgba(101, 100, 100, 0.75);
  margin: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
  font-weight: 100;
}

.price_info_color_third {
  width: 20px;
  height: 20px;
  background-color: white;
  box-shadow: 1px 2px 1px 0px rgba(101, 100, 100, 0.75);
  margin: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
  font-weight: 100;
}

.node_price_first {
  background-color: #b9a6e0;
}
.node_price_second {
  background-color: #d5cfdf;
}


.header_pc {
  display: none;
  justify-content: space-between;
  color: lightgrey;
}

@media screen and (min-width: 800px) {
  .header_pc {
    display: flex;
  }
}

.header_mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  /* background-color: rgb(14, 2, 40, 0.8); */
  color: lightgrey;
  z-index: 1;
  /* opacity: 0.1; */
}

@media screen and (min-width: 800px) {
  .header_mobile {
    display: none;
  }
}

.header_logo_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.header_logo {
  height: 30px;
}

.header_text {
  font-family: 'YoungSerif';
  font-weight: bold;
  font-size: large;
  margin: 0px;
}

.header_menu {
  display: flex;
  gap: 1rem;
}

.header_menu {
  list-style: none;
}

@media screen and (min-width: 800px) {
  .header_logo {
    height: 50px;
  }

  .header_menu {
    gap: 3rem;
  }

  .header_text {
    font-size: x-large;
  }
}



.mobileNavContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background: rgb(47, 47, 47);
}


.mobile_header_menu {
  list-style-type: none;
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

.navigation {
  display: flex;
  align-items: center;
}



.header_menu li a {
  position: relative;
  text-decoration: none;
  color: lightgrey;
  font-family: 'YoungSerif';
  font-size: small;
  margin: 0;
}

.header_menu li a:hover,
.header_menu li a:active {
  color: rgba(192, 234, 10, 1);
}

.home {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}


.header {
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 6rem;
  background: transparent;
  padding: 0 4rem;
  align-items: flex-start;
}


.hero_img {
  width: 100%;
  height: 100vh;
  background-image: url('/public/bg-8.jpg');
  background-size: cover;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.headline {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  padding-left: 5%;
  gap: 10px;
  font-size: 10vw;
  overflow: hidden;
}

@media screen and (min-width: 800px) {
  .headline {
    width: 70%;
    padding-left: 20%;
    gap: 30px;
    font-size: 5vw;
  }

  .header_menu li a {
    font-size: large;
  }
}

@keyframes hue {
  from {
    filter: hue-rotate(0deg);
  }

  to {
    filter: hue-rotate(360deg);
  }
}

/* .face_pre {
  font-family: 'YoungSerif';
  font-size: 5vw;
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-image: linear-gradient(to right, whitesmoke, rgb(192, 234, 10, 1), white);
} */

.flow_slogan {
  font-family: 'YoungSerif';
  /* font-size: 5vw; */
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* background-image: linear-gradient(to right, red, yellow, lime, aqua, blue, rgb(144, 0, 255)); */
  background-image: linear-gradient(to right, white, rgb(192, 234, 10, 1), white);
  animation: hue 6s linear infinite;
}

.hero_info {
  width: 90%;
  text-align: start;
  font-family: 'YoungSerif';
  color: whitesmoke;
  font-size: 3vw;
  padding-left: 5%;
  overflow: hidden;
}

@media screen and (min-width:800px) {
  .hero_info {
    width: 65%;
    font-size: 1.2vw;
    padding-left: 10%;
  }
}

.back_home {
  /* width: 100%; */
  color: whitesmoke;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* padding-left: 30px; */
  /* gap: 5px; */
  text-decoration: none;
}

.back_home a:hover,
.back_home a:active {
  color: rgba(192, 234, 10, 1);
}

@media screen and (min-width:800px) {
  .back_home {
    padding-left: 80px;
    /* width: 80%; */
  }
}

.back_home_text {
  margin: 0px;
}


.face_font {
  backface-visibility: hidden;
}

.face_info_input_group {
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.face_info_input {
  /* display: flex;
  flex-direction: column;
  align-items: flex-start; */
  width: 49%;
}

.face_name_label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.face_name_input {
  height: 40px;
}

.face_name_checkbox {
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.thumb_info {
  /* width: 400px; */
  position: absolute;
  overflow: visible;
  height: 128px;
  background-color: #313131;
  top: -110px;
  color: whitesmoke;
  font-size: small;
  display: flex;
  align-items: center;
  transform: scale(66.7%);
}

.thumb_info_img {
  width: 128px;
}

.thumb_info_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
}

.thumb_info_text {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 3px;
}

.qa_main_container {
  width: 100%;
  min-height: 95vh;
  background-color: #2f2f2f;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.qa_heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
  margin: 1rem;
}

.qa_heading_img {
  height: 30px;
  margin-left: 2rem;
}

@media screen and (min-width: 800px) {
  .qa_heading_img {
    height: 50px;
    margin-left: 6rem;
  }
}

.qa_heading_text {
  font-family: 'YoungSerif';
  font-weight: bold;
  font-size: large;
  margin: 0px;
  color: whitesmoke;
}

.qa_question {
  color: rgba(192, 234, 10, 1);
  font-family: 'YoungSerif';
  font-size: 3.6vw;
}

@media screen and (min-width:800px) {
  .qa_question {
    font-size: 1.4vw;
  }
}

.qa_answer {
  color: whitesmoke;
  font-family: 'YoungSerif';
  font-size: 2.5vw;
  font-weight: lighter;
}

@media screen and (min-width:800px) {
  .qa_answer {
    font-size: 1vw;
  }
}

.qa_content {
  width: 100%;
  padding: 0 1rem;
}

@media screen and (min-width:800px) {
  .qa_content {
    padding: 0 5rem;
  }
}

.credit_menu {
  border-radius: 5px;
  background-color: whitesmoke;

}

.referrence_code {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  gap: 10px;
}

.referrence_code_label {
  word-break: keep-all;

}

.referrence_code_input {
  width: auto;
}

.intro_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2f2f2f;
}

@media screen and (min-width:800px) {
  .intro_container {
    flex-direction: row;
  }
}

.intro_text_container {
  font-family: 'YoungSerif';
  padding-bottom: 30px;
  padding: 10px;
}

.intro_text_title {
  text-align: center;
  font-size: medium;
  color: rgb(192, 234, 10, 1);
}

.intro_text_content {
  color: whitesmoke;
  font-size: x-small;
}

@media screen and (min-width:800px) {
  .intro_text_title {
    font-size: large;
  }

  .intro_text_content {
    font-size: small;
  }
}

.intro_img {
  width: 100%;
}

.keytech_intro_text {
  font-family: 'YoungSerif';
  color: whitesmoke;
  font-size: x-small;
  padding: 10px;
}

.keytech_hexagon_container {
  width: 100%;
}

.keytech_hexagon_container:last-child {
  margin-bottom: 40px;
}

.keytech_hexagon_row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-family: 'YoungSerif';
  color: whitesmoke;
  font-size: xx-small;
}

.keytech_hexagon {
  width: 7vw;
  fill: rgb(192, 234, 10, 1);
  padding-left: 20px;
  padding: 10px;
  /* padding-left: 30px; */
}

.keytech_hexagon_gold {
  width: 7vw;
  fill: gold;
  padding: 10px;
}

.keytech_text {
  max-width: 80vw;
}

.keytech_hexagon_hidden {
  width: 30vw;
  height: 30vw;
  fill: #1f1f1f;
}

@media screen and (min-width:800px) {
  .keytech_intro_text {
    font-size: large;
  }

  .keytech_hexagon {
    width: 50px;
    padding-left: 40px;
  }

  .keytech_hexagon_gold {
    width: 50px;
    padding-left: 40px;

  }

  .keytech_hexagon_row {
    font-size: small;
  }
}


.section_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1f1f1f;
}

.section_heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
  padding-top: 1rem;
}

.section_heading_img {
  height: 30px;
  margin-left: 2rem;
}

@media screen and (min-width: 800px) {
  .section_heading_img {
    height: 50px;
    margin-left: 6rem;
  }
}

.section_heading_text {
  font-family: 'YoungSerif';
  font-weight: bold;
  font-size: large;
  margin: 0px;
  color: whitesmoke;
}

.seed_container {
  width: 100%;
  background-color: #2f2f2f;
}

.seed_intro_img {
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;
}

.seed_intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;

}

.seed_intro_title {
  font-family: 'YoungSerif';
  font-size: small;
  color: #c0ea0a;
  /* margin: 10px; */
  /* padding-left: 20px;
  padding-right: 20px; */
}

.seed_intro_text {
  font-family: 'YoungSerif';
  font-size: x-small;
  color: whitesmoke;
  margin: 0;
  /* padding-left: 20px;
  padding-right: 20px; */
}

@media screen and (min-width:800px) {
  .seed_content {
    display: flex;
    align-items: center;
  }

  .seed_intro_img {
    width: 500px;
    padding: 30px;
    border-radius: 40px;
  }

  .seed_intro_title {
    font-size: large;
  }

  .seed_intro_text {
    font-size: small;
  }

}



.roadmap_container {
  width: 100%;
  background-color: #1f1f1f;

}



.roadmap {
  font-family: 'YoungSerif';
  margin: 0;
  padding: 0 1em;
  width: 85%;
  min-width: 200px;
  margin: auto;
}


.roadmap_text {
  font-size: x-small;
  line-height: 1.75em;
  border-top: 3px solid;
  border-image: linear-gradient(to right, #c0ea0a 0%, whitesmoke 100%);
  border-image-slice: 1;
  border-width: 3px;
  margin: 0;
  padding: 40px;
  counter-increment: section;
  position: relative;
  color: whitesmoke;

  &:before {
    content: counter(section);
    position: absolute;
    border-radius: 50%;
    padding: 15px;
    height: 1.25em;
    width: 1.25em;
    background-color: #c0ea0a;
    text-align: center;
    line-height: 1.25em;
    color: black;
    font-size: 1em;
  }
}

.roadmap_text:nth-child(odd) {
  border-right: 3px solid;
  padding-left: 0;

  &:before {
    left: 100%;
    margin-left: -20px;
  }
}

.roadmap_text:nth-child(even) {
  border-left: 3px solid;
  padding-right: 0;

  &:before {
    right: 100%;
    margin-right: -20px;
  }
}

.roadmap_text:first-child {
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.roadmap_text:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}


@media screen and (min-width:800px) {
  .roadmap {
    width: 50%;
  }

  .roadmap_text {
    font-size: small;
  }
}